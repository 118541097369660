export const homeHeroComponents = [
  {
    label: "Mapy",
    text: () => {
      return (
        <div className='flex flex-col gap-2'>
          <span>
            DeerIsle to rozległa, zróżnicowana wyspa, oferująca zarówno gęste lasy, jak i opustoszałe miasteczka, idealna do eksploracji i ciągłej walki z przeciwnościami losu.
          </span>
          <span>
            Z kolei Sakhal to mroczna, surowa wyspa, której trudne warunki terenowe i surowy klimat stanowią prawdziwe wyzwanie dla przetrwania.
          </span>
          <span>
            Obie mapy zapewniają unikalne doświadczenia, pełne nieprzewidywalnych sytuacji i emocjonujących momentów.
          </span>
        </div>
      )
    },
    image: "/images/hero/map.jpg",
    background: '',
    buttons: () => {
        return (
        <>
        </>
      )
    }
  },
  {
    label: "Immersja",
    text: () => {
      return (
        <div className='flex flex-col gap-2'>
          <span>
            Na mapie DeerIsle wprowadzono szereg zmian, które zwiększają immersję i dodają amerykański charakter. Znajdziemy tam wraki amerykańskich pojazdów, które dodają autentyczności do środowiska. Podmieniono szereg budynków na takie aby bardziej odpowiadały amerykańskim realiom.
          </span>
          <span>
            Taki zabieg pozwoli graczom na lepsze wczucie się w klimat regionu oraz LORE serwera.
          </span>
        </div>
      )
    },
    image: "/images/hero/pojazdy.jpg",
    background: 'transparent',
  },
  {
    label: "Realizm i rozwój postaci",
    text: () => {
      return (
        <div className='flex flex-col gap-2'>
          <span>
            Nowy bardziej złożony system medyczny. Gracze muszą zarządzać zdrowiem swojej postaci w sposób bardziej realistyczny – leczyć rany, radzić sobie z chorobami czy skutkami kontuzji. Dzięki temu aspekt medyczny staje się ważnym elementem przetrwania w grze
          </span>
          <span>
            Nowy system rozwoju umiejętności. Postać może rozwijać różne zdolności w zależności od wykonywanych działań. Dodaje to nową warstwę głębi do rozgrywki, czyniąc rozwój postaci kluczowym elementem przetrwania.
          </span>
        </div>
      )
    },
    image: "/images/hero/character.jpg",
    background: ''
  },
  {
    label: "Zdarzenia",
    text: () => {
      return (
        <div className='flex flex-col gap-2'>
          <span>
            Pojawiły się nowe zdarzenia, w tym pojazdy SWAT, karetki, busy oraz więcej, wokół których gracze mogą znaleźć różnorodne przedmioty.
          </span>
          <span>
            Te dodatki wzbogacają istniejące już wydarzenia, takie jak pociągi, policyjne blokady czy wraki helikopterów, dodając nowe możliwości eksploracji i zdobywania przedmiotów.
          </span>
        </div>
      )
    },
    image: "/images/hero/events.jpg",
    background: ''
  },
  {
    label: "Mini-bossy zombie",
    text: () => {
      return (
        <div className='flex flex-col gap-2'>
          <span>
            Podróżując możesz napotkać wiele zombie, które tylko czychają, aby kogoś dopaść. Są miejsca, w których spotkasz nietypowe umarlaki.
          </span>
          <span>
            Każdy z nich ma unikalne cechy, które definiują walkę z nimi. Jedne są bardziej wytrzymałe, drugie mają ogromną siłę, a trzecie potrafią w sekundę pozbawić życia.
          </span>
          <span>
            Eksplorując uważaj na siebie, nigdy nie wiesz Na co lub kogo natrafisz. Ile jeszcze jest nieznanych stworzeń?
          </span>
        </div>
      )
    },
    image: "/images/hero/boss.jpg",
    background: 'transparent',
  },
]