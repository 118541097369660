import React, { ReactNode } from 'react'
import { motion, useAnimation } from "framer-motion"
import { useInView } from 'react-intersection-observer'
import { Container } from '../Container'

interface HeroComponent {
  label: string
  background: string
  image: string
  text: () => ReactNode
  buttons?: () => ReactNode
}

function Hero({ component, index }: { component: HeroComponent, index: number }) {
  const controls = useAnimation()
  const { ref, inView } = useInView()

  React.useEffect(() => {
    if (inView) {
      controls.start({
        transition: {
          duration: 3,
          type: 'spring',
          stiffness: 260,
          damping: 20
        }
      })
      controls.start("visible")
    }
  }, [controls, inView])

  return (
    <Container>
    <motion.div
      ref={ref}
      initial="hidden"
      animate={controls}
      variants={{
        visible: { opacity: 1, y: 0 },
        hidden: { opacity: 0, y: 50 }
      }}>
      {/* <div className="w-full rounded-lg transition-all ease-in-out hover:scale-105" style={{backgroundImage: `url('${component.image}')`, backgroundPosition: "center", backgroundSize: "cover" }}>
        <div className='flex flex-col w-full rounded-lg p-2' style={{ backdropFilter: 'blur(5px) grayscale(.5) brightness(.3)' }}> */}
          <div key={component.label} className={`hero pt-2 pb-2 w-full`}>
            <div className={`hero-content p-0 flex-col gap-2 ${(index + 1) % 2 === 0 ? 'md:flex-row-reverse' : 'md:flex-row'}`}>
              <img
              src={component.image}
              className="max-w-sm max-lg:w-full rounded-lg 2xl" />
              <div className='flex flex-col gap-2 rounded-lg p-2'>
                <h1 className={`text-5xl font-bold w-full ${(index + 1) % 2 === 0 ? 'lg:text-right' : 'lg:text-left'} max-md:text-center`}>
                  {component.label}
                </h1>
                <span className='w-full text-justify'>{component.text()}</span>
                {component.buttons && component.buttons()}
              </div>
            </div>
          </div>
        {/* </div>
      </div> */}
    </motion.div>
    </Container>
  )
}

export default Hero