import React from 'react'

export const Container = ({ children }: { children: React.ReactNode}) => {
  return (
    <div className='w-full flex justify-center p-3'>
      <div className='w-full max-w-screen-xl'>
        {children}
      </div>
    </div>
  )
}