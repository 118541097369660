'use client'
import React from 'react'
import {
  useScroll,
  motion,
  useTransform,
} from 'framer-motion'
import { ArrowDown } from 'lucide-react'

export const ParalaxBaner = () => {
  const { scrollY } = useScroll()
  const y1 = useTransform(scrollY, [0, 1800], [0, -100])
  const y2 = useTransform(scrollY, [0, -300], [0, -50])
  const y3 = useTransform(scrollY, [0, 1700], [0, -100])
  const y4 = useTransform(scrollY, [0, 1500], [0, -300])

  const handleScrollBtn = () => {
    const targetDiv = document.querySelector('.paralax') as HTMLElement
    window.scrollTo({ top: targetDiv?.offsetTop + window.innerHeight - 128, behavior: 'smooth' })
  }

  return (
    <div className='paralax relative w-100 h-[calc(100vh-64px)] bg-slate-600 z-10 overflow-hidden'>
      <motion.div
        className='absolute w-100 top-0 right-0'
        style={{ y: y1, scale: 1.3,
          height: '100%',
          width: '100%',
          backgroundImage: 'url("/images/paralax/main2.png")',
          backgroundPosition: 'center',
          backgroundSize: 'cover',
        }} />
      <motion.div
        className='absolute w-100 bottom-0 right-0'
        style={{ y: y2, scale: 1.2,
          height: '100%',
          width: '100%',
          backgroundImage: 'url("/images/paralax/main0.png")',
          backgroundPosition: 'center',
          backgroundSize: 'cover',
        }} />
      <div className='absolute flex justify-center items-center h-[calc(100vh-60px)] w-full top-0 left-0'>
        <motion.img
          className='w-full max-w-[1280px] p-2'
          src='/logo/hrp_logo.png'
          style={{ y: y3 }} />
      </div>
      <motion.div
        className='absolute bottom-[-150px] right-0'
        style={{ y: y4, scale: 1.35,
          height: '100%',
          width: '100%',
          backgroundImage: 'url("/images/paralax/main1.png")',
          backgroundPosition: 'center',
          backgroundSize: 'cover',
        }}
      />
      <div className='absolute flex p-12 justify-center items-center w-[100vw] bottom-0 left-0'>
        <button onClick={handleScrollBtn} className='btn btn-circle btn-outline backdrop-blur-lg'>
          <ArrowDown />
        </button>
      </div>
    </div>
  )
}
