'use client'
import React from 'react'
import { Container } from '../Container'

const featuresList = [ "ls", "kk", "wz" ]

const features = [
  {
    id: 'ls',
    title: 'Lech Siarzewski',
    short: 'Stary pierd i ojciec ponad dwudziestki po zęby uzbrojonych kundli.',
    desc: [
      'Ludzie mówili o nim różnie: zbok, alkoholik, ćpun, psychol, kochający opiekun, kombinator, cham, nałogowy ekshibicjonista, jednak skurwysynowi nikt nie mógł podskoczyć. Mimo musztry stylem sięgającym jeszcze czasy PRLu, łatwo odnajdywał się pośród nowych wyzwań i dogadywał się z ludźmi z każdego pokolenia. Niesamowity przywódca na czele wielu wygranych bitew, potrafiący wzbić morale swoich podwładnych po nawet najgorszej porażce.',
      'Wiedział co, ile i w jaki sposób robić, by ugrać coś dla siebie i swojej ekipy, począwszy od nielegalnych działań zbrojnych w postaci założenia grupy "operacyjno-taktycznej" Overlord, po założenie baru, sadzenie trawy i przekonanie do siebie aktorki porno w ramach pasywnego zarobku. Kochał ludzi, chętnie zapraszał nieznajomych na "kielicha", jednak nie musiał zastanawiać się dwa razy, by przyłożyć wrogom pistolet do skroni, czy też wbić kij baseballowy w dupę.'
    ],
    image: '/images/topseason11/siarzewski.webp'
  },
  {
    id: 'kk',
    title: 'Krystian Kajak',
    short: 'Wychowany w domu dziecka, nauczył się dyscypliny i niesienia pomocy innym. Z wykształcenia lekarz, był jednym z założycieli MOPS-u.',
    desc: [
      'Niezależnie od trudności, które spotykały go w życiu, zawsze starał się pomagać potrzebującym. Przez lata żył w przekonaniu, że został porzucony przez rodziców. Dopiero później odkrył, że prawda była zupełnie inna, jego rodzice musieli się ukrywać, a mimo to zostali zamordowani przez Białorusinów. Z czasem Krystian dowiedział się, że jego biologiczny ojciec żyje, co początkowo było dla niego trudne do zaakceptowania.',
      'Seria tragicznych wydarzeń, zaginięcie ojca, zdrada przyjaciela, śmierć przyjaciółki i innych bliskich osób z rąk Białorusinów, odcisnęła na nim trwałe piętno. Z dawniej całkowitego abstynenta, przemienił się w człowieka sięgającego po alkohol, a jego psychika uległa załamaniu. Jedynym, co potrafił robić, było szukanie zemsty. Z bronią ojca w ręku i kasetą z utworem "Orła Cień", wyruszył w podróż po Białorusi, zabijając po drodze tylu wrogów, ilu tylko mógł.',
    ],
    image: '/images/topseason11/kajak.webp'
  },
  {
    id: 'wz',
    title: 'Wojtek Żeromski',
    short: 'Finansista, ekspert , filantrop, uwodziciel tak pewnie powiedział by o sobie Wojtek jednak wszyscy wiemy jak było.',
    desc: [
      'Postać Wojtka Żeromskiego przechodziła podczas sezonu kilka przemian niczym z książek Adama Mickiewicza. Zaczynał jako prosty chłopak z osiedla walczący nie tylko z zarazą, ale i o dobre imię swojego ulubionego klubu, któremu pozostał wdzięczny aż do końca. Wojtek z czasem wraz z grupą swoich najbliższych przyjaciół i rudego zaczerpnęli wiedzy Cechu Dresa i przemienili się w Wiedzminów ... Wiemdzminów ... Wiemdzinów ... do dziś nie umiem tego wypowiedzieć wtedy Wojtek nabył kolejny życiowy cel czyli przekazać swą miłość do atrybutów Valentiny co jednak szybko zniszczył Ratel.',
      'Po ucieczce całego Podlasia na Białoruś Wojtek wraz ze zgrają postanowili zasilić szeregi samej Matrioszki gdzie porywali ocalałych bawili się nimi jak zabawkami oj co się tam nie działo w tych cholernych celach kto nie widział ten lepiej teraz sypia. Niestety Wojtek wraz z braćmi tragicznie zginął przez zdradę swojego cechu a przynajmniej tak twierdzi legenda o "Małym" Wojtku Żeromskim.'
    ],
    image: '/images/topseason11/zeromski.webp'
  },
];


export const Features = () => {
  const [showId, setShowId] = React.useState('kk')

  React.useEffect(() => {
    
    const feature = document.querySelector(`#${showId}`)

    if (feature) {
      const featureShort = document.querySelector(`#${showId}-short`)
      const featureDesc = document.querySelector(`#${showId}-desc`)
      setTimeout(() => {
        featureShort?.classList.add('hidden')
        featureDesc?.classList.remove('hidden')
      }, 100)
      if (window.innerWidth >= 768) feature.classList.replace('w-1/4', 'w-2/4')
    }

    featuresList.forEach(feature => {
      if (feature === showId) return

      const featureOther = document.querySelector(`#${feature}`) as HTMLDivElement

      if (window.innerWidth >= 768) featureOther.classList.replace('w-2/4', 'w-1/4')
      const featureShort = document.querySelector(`#${feature}-short`)
      const featureDesc = document.querySelector(`#${feature}-desc`)
      featureShort?.classList.remove('hidden')
      featureDesc?.classList.add('hidden')
    })
  }, [showId])

  const toggleFeature = (event: React.MouseEvent<HTMLDivElement>) => {
    const feature = event.currentTarget
    setShowId(feature.id)
  }

  return (
    <Container>
      <div className='w-100 text-center mb-3'>
        <h2 className='text-3xl font-bold'>Wyróżnione postacie 11 sezonu</h2>
      </div>
      <div className='flex max-md:flex-col gap-2'>
      {features.map((feature) => (
        <div
          key={feature.id}
          onClick={toggleFeature}
          id={feature.id}
          className="w-1/4 max-md:w-full h-[500px] rounded-lg radius-lg 2xl hover:cursor-pointer transition-all"
          style={{backgroundImage: `url('${feature.image}')`, backgroundPosition: "center", backgroundSize: "cover" }}
        >
          <div className='flex items-end w-full h-full'>
            <div className='flex flex-col gap-0.5 p-2 bg-[#191919c0] rounded-b-lg'>
              <span id={`${feature.id}-title`} className='text-lg font-bold'>{feature.title}</span>
              <span id={`${feature.id}-short`} className='text-sm transition-all'>{feature.short}</span>
              <div id={`${feature.id}-desc`} className='flex flex-col text-sm gap-1 transition-all'>
                {feature.desc.map((paragraph, index) => (
                  <span key={index}>{paragraph}</span>
                ))}
              </div>
            </div>
          </div>
        </div>
      ))}

      </div>
    </Container>
  )
}