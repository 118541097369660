'use client'
import { Features } from '../components/Home/Features'
import MainHero from '../components/Home/MainHero'
import MainHeroesNew from '../components/Home/MainHeroesNew'
import NewSeasonPreview from '../components/Home/NewSeasonPreview'
import { ParalaxBaner } from '../components/Home/ParalaxBaner'

export default function Page() {
  return (
    <main>
      <ParalaxBaner />
      <MainHero />
      <Features />
      {/* <NewSeasonPreview /> */}
      <MainHeroesNew />
    </main>
  )
}
