'use client'
import { homeHeroComponents } from "../../data/homeHeroComponents"
import Hero from "./Hero"

const MainHeroesNew = () => {
  return (
    <div className="heroes flex flex-col gap-6 justify-center w-full">
      {
        homeHeroComponents.map((component, index) => (
          <Hero key={index} index={index} component={component} />
        ))
      }
    </div>
  )
}

export default MainHeroesNew